import root from "window-or-global";

export const executeScroll = (myRef) => {
  if (root.scrollTo && myRef.current) {
    root.scrollTo({
      top: myRef.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }
}

export function scrollToElement(element) {
  if (element) {
    return element.scrollIntoView && element.scrollIntoView({behavior: "smooth"});
  }
}