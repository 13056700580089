import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import Logo from "../../images/why/getgud_ipv4_ipv6.svg"

export default function IPSection(props) {
  return (
    <div className="ip-section">
      <Container>
        <Row>
          <Col>
            <img src={Logo} alt="logo" className="ip-img" />
          </Col>
          <Col xs={12} lg={8}>
            <div className="sub-text font-secondary vertical-center pb-md-4">
              <span className="font-orange font-primary-bold">GetGud</span> also gives you an included <span className="heavy">external IPv4</span> ready for gaming.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}