import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NoLockInImg from "../../images/why/nolockin.svg"
import supportImg from "../../images/why/networksupport.svg";
import seamlessSignup from "../../images/why/seamlesssignup.svg";
import gamesImg from "../../images/why/greatforgames.svg";
import { useQueryParam, StringParam } from "use-query-params";
import { executeScroll } from "./utils";

export default function WhySection(props) {
	const myRef = useRef(null);
	const [defaultTab] = useQueryParam("section", StringParam);

	useEffect(() => {
		if (defaultTab === "why") {
			executeScroll(myRef);
		}
	}, [defaultTab, myRef]);
	return (
		<div className="why-section section-bg" ref={myRef}>
			<Container>
				<Row>
					<Col>
						<div className="main-text font-white">
							What's all the <span className="font-orange">fuss</span>?
						</div>
						<div className="sub-text font-secondary font-white">
							{"We are an Internet service provider catering to the top-performing network users"}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6}>
						<div className="why-item">
							<img src={NoLockInImg} alt="no lock in" className="why-img" />
							<div className="why-title font-cyan">
								{"No lock-in contract"}
							</div>
							<div className="why-subtitle font-white">
								{"Our users stay with us because we deliver the network speeds and service they require."}
							</div>
 						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className="why-item">
							<img src={supportImg} alt="no lock in" className="why-img" />
							<div className="why-title font-cyan">
								{"Network support"}
							</div>
							<div className="why-subtitle font-white">
								{"We want our users to remain connected; that is why we provide extended support & customer service times."}
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6}>
						<div className="why-item">
							<img src={seamlessSignup} alt="no lock in" className="why-img" />
							<div className="why-title font-cyan">
								{"Seamless sign-up"}
							</div>
							<div className="why-subtitle font-white">
								{"GetGud makes connecting to the internet easy. Users can sign-up to the network from the moment they enter their apartment"}
							</div>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className="why-item">
							<img src={gamesImg} alt="no lock in" className="why-img" />
							<div className="why-title font-cyan">
								{"Great for Games"}
							</div>
							<div className="why-subtitle font-white">
								{"We peer with major Gaming and Content Providers: Google, Valve, Riot Games, Twitch, Apple, Akamai, Blizzard, Microsoft, Netflix for fast connection and low ping."}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}