import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Background from "../../images/peer/whiteBG.svg";
import googleImg from "../../images/peer/peernetwork_google.svg";
import valveImg from "../../images/peer/peernetwork_valve.svg";
import riotImg from "../../images/peer/peernetwork_riot.svg";

import twitchImg from "../../images/peer/peernetwork_twitch.svg";
import appleImg from "../../images/peer/peernetwork_apple.svg";
import akamaiImg from "../../images/peer/peernetwork_akamai.svg";

import blizzardImg from "../../images/peer/peernetwork_blizzard.svg";
import microsoftImg from "../../images/peer/peernetwork_microsoft.svg";
import netflixImg from "../../images/peer/peernetwork_netflix.svg";


export default function PeerSection(props) {
  return (
    <div className="peer-section" style={{
      backgroundImage: `url(${Background})`
    }}>
      <Container className="peer-container">
        <Row>
          <Col xs={12} lg={6}>
            <div className="desc vertical-center mb-5 mb-lg-0 heavy">
              {"We peer with the major gaming and content providers, increasing your connectivity with the online community."}
            </div>
          </Col>
          <Col xs={12} lg={6} className="mx-auto">
            <Row>
              <div className="d-flex w-100 justify-content-center">
                <div className="peer-item">
                  <img src={googleImg} alt="google" class="peer-img" />
                </div>
                <div className="peer-item">
                  <img src={valveImg} alt="valve" class="peer-img" />
                </div>
                <div className="peer-item">
                  <img src={riotImg} alt="riot" class="peer-img" />
                </div>
              </div>
            </Row>
            <Row>
              <div className="d-flex w-100 justify-content-center">
                <div className="peer-item">
                  <img src={twitchImg} alt="twitch" class="peer-img" />
                </div>
                <div className="peer-item">
                  <img src={appleImg} alt="apple" class="peer-img" />
                </div>
                <div className="peer-item">
                  <img src={akamaiImg} alt="akamai" class="peer-img" />
                </div>
              </div>
            </Row>
            <Row>
              <div className="d-flex w-100 justify-content-center">
                <div className="peer-item">
                  <img src={blizzardImg} alt="blizzard" class="peer-img" />
                </div>
                <div className="peer-item">
                  <img src={microsoftImg} alt="microsoft" class="peer-img" />
                </div>
                <div className="peer-item">
                  <img src={netflixImg} alt="netflix" class="peer-img" />
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}