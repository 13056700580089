import React, {Fragment} from "react";
import {useQuery} from "@apollo/client";
import {Col, Button, Row} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";

import impressiveMeter from "../../images/plan/impressive.svg";
import {getProductsQuery, getProductsResult} from "../logic/products";
import {setFormData, getFormData} from "../manager/form";
import VoucherModal from "./voucher";
import { NON_INTERNET_PRODUCT_TYPES_TO_SHOW } from "../../utils/constants";

const ProductSelector = ({onSelectProduct, buildingCode, cisLink}) => {
  const [showVoucher, setShowVoucher] = useAsyncSetState(false);
  const {voucherCode = "", serviceAvailability, building, selectedProducts: formProducts, isNewConnection, locationId, accessCircuitIds} = getFormData();
  const [selectedProducts, setSelectedProducts] = useAsyncSetState([]);
  const [internetPlan, setInternetPlan] = useAsyncSetState(null);
  const variables = {
    buildingCode,
    voucherCode,
    showHidden: true,
    selectedProductIds: []
  };
  const pageInfoQuery = useQuery(getProductsQuery, {variables});
  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  let accessCircuitRef = locationId;
  if (locationId.startsWith("VLC")) {
    accessCircuitRef = accessCircuitIds[0];
  }
  if (!accessCircuitRef.startsWith("VAC")) {
    throw new Error("Invalid access circuit ref");
  }
  const products = getProductsResult(pageInfoQuery).map((product) => ({
    ...product,
    orderItemOpts: {
      accessCircuitRef,
    },
  }));

  const planProducts = (products || []).filter(
    (product) => product.hidden === false && product.type === "internet"
  ).filter(p => (internetPlan && p.id === internetPlan.id) || !internetPlan);


  const hardwareProductsDisplay = products.filter(({type}) => type === "hardware");
  const addOnProductsDisplay = products.filter(({type}) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(type));
  const monthly = selectedProducts
    .filter(({ type }) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(type))
    .filter(({once}) => !once)
    .reduce((prev, value) => prev + Number(value.value), 0);
  let initialPayment = (internetPlan?.value || 0) +
    selectedProducts.filter((sp) => sp.id !== internetPlan?.id)
    .reduce((prev, value) => prev + Number(value.value), 0);

  let newConnectionProduct;
  if (isNewConnection) {
    newConnectionProduct = products.find((product) => product?.onlyNewConnection === true);
    if (newConnectionProduct) {
      setFormData({ newConnectionProduct });
      if ((newConnectionProduct?.value || 0) > 0 && !isNaN(newConnectionProduct?.value)) {
        initialPayment += newConnectionProduct?.value;
      }
    }
  }

  const handleToggleChange = async (product) => {
    if (selectedProducts.find((sp) => sp.id === product.id)) {
      return setSelectedProducts(
        [...selectedProducts].filter((sp) => sp.id !== product.id)
      );
    }

    const selectevents = product?.selectEventsActivate || [];
    let newAddons = [];

    (selectevents || []).forEach((event) => {
      const eventProduct = products.find((p) => (p.name || "").toLowerCase() === (event || "").toLowerCase());
      if (eventProduct) {
        newAddons.push(eventProduct);
      }
    });


    let newProducts = selectedProducts.concat([product, ...newAddons]);
    newProducts = newProducts.filter((p, i) => newProducts.findIndex((np) => np.id === p.id) === i);
    console.log({newProducts});
    return setSelectedProducts(newProducts);
  };

  return (
    <Fragment>
      {showVoucher && (
        <VoucherModal
          setVoucher={async (data) => {
            return pageInfoQuery.refetch({
              buildingCode,
              voucherCode,
            });
          }}
          onClose={async () => setShowVoucher(false)}
        />
      )}
      <Row className="align-items-center">
        <Col xs={12}>
          <h2 class="main-text font-white mb-8 text-center">Choose your Plan</h2>
          {!voucherCode ? (
            <a
              onClick={async () => setShowVoucher(true)}
              className="pointer-cursor sub-text text-center mx-auto d-block voucher-link">
              {"click here to apply voucher"}
            </a>
          ) : (
            <a
              onClick={async () => {
                setFormData({ voucherCode: "" });
                return pageInfoQuery.refetch();
              }}
              className="pointer-cursor sub-text text-center mx-auto d-block voucher-link">
              {"click here to remove voucher"}
            </a>
          )}
          <div className="product-selector-body">
            <div class="container">
              {planProducts.map((product) => {
                  return (
                    <Col xs={12} sm={12} lg={4} className="d-block mx-auto mb-8">
                      <div className="p-0 mb-1 mx-auto mt-0 border-0 d-block">
                        <div className="plan-item text-center">
                          <img
                            src={impressiveMeter}
                            alt="not so basic meter"
                            className="plan-meter"
                          />
                          <div className="plan-title font-darkblue font-primary-bold">
                            {product.name || ""}
                          </div>
                          <div className="plan-price">
                            <span className="font-orange price-value">
                              {`$${Number(product.value)}`}
                            </span>
                            <span className="price-freq">{"/month"}</span>
                          </div>
                          <div className="plan-rate font-darkblue mb-3">
                            {product.description || ""}
                          </div>
                          {!internetPlan && <Button
                            onClick={async () => {
                              await setInternetPlan(product);
                              const selectevents = product?.selectEventsActivate || [];
                              let addons = [];

                              (selectevents || []).forEach((event) => {
                                const eventProduct = products.find((p) => (p.name || "").toLowerCase() === (event || "").toLowerCase());
                                if (eventProduct) {
                                  addons.push(eventProduct);
                                }
                              });
                              let newSelectedProducts = (formProducts || []).concat(addons || []);
                              newSelectedProducts = newSelectedProducts.filter((p, i) => newSelectedProducts.findIndex((np) => np.id === p.id) === i);
                              setFormData({
                                selectedProducts: newSelectedProducts,
                                selectedProduct: product,
                              });
                              await setSelectedProducts(newSelectedProducts);

                              const element = document?.getElementById("review");
                              element?.scrollIntoView && element.scrollIntoView({behavior: "smooth"});
                            }}
                            variant="orange"
                            className="btn-wide">
                            {"Get it"}
                          </Button>}
                          {internetPlan && (
                            <Button
                              onClick={() => {
                                setInternetPlan(null)
                                setFormData({
                                  selectedProducts: [],
                                  selectedProduct: null,
                                });
                              }}
                            >
                              Change Plan
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  )
              })}
            </div>
          </div>
        </Col>
        <Col
          key={internetPlan?.id || 0}
          xs={8}
          id="review"
          className={`mx-auto ${internetPlan ? "d-block" : "d-none"}`}>
          <div className="product-addons">
            {hardwareProductsDisplay.length > 0 && (
              <div className="title bold font-orange text-center">
                {"Hardware"}
              </div>
            )}
            <Row className="mb-3">
              {hardwareProductsDisplay.length > 0 &&
                hardwareProductsDisplay.map((product) => (
                  <Col key={product.id} xs={6}>
                    <div className="custom-control my-3 custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`${product.id}`}
                        checked={selectedProducts.find((sp) => sp.id === product.id)}
                        onClick={() => handleToggleChange(product)}
                      />
                      <label
                        className="custom-control-label bold"
                        htmlFor={`${product.id}`}>
                        {product.name}
                        <span className="font-orange ml-2">
                          {`($${parseFloat(product.value)}/${product.once? "Once" : "Monthly"})`}
                        </span>
                      </label>
                    </div>
                  </Col>
                ))}
            </Row>
            {addOnProductsDisplay.length > 0 && (
              <div className="title bold font-orange text-center">
                {"Additional Addons"}
              </div>
            )}
            <Row className="mb-3">
              {addOnProductsDisplay.length > 0 &&
                addOnProductsDisplay.map((product) => (
                  <Col key={product.id} xs={6}>
                    <div
                      key={product.id}
                      className="custom-control my-3 custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`${product.id}`}
                        checked={selectedProducts.find((sp) => sp.id === product.id)}
                        onClick={() => handleToggleChange(product)}
                      />
                      <label
                        className="custom-control-label bold"
                        htmlFor={`${product.id}`}>
                        {product.name}
                        <span className="font-orange ml-2">
                          {`($${parseFloat(product.value)}/${product.once? "Once" : "Monthly"})`}
                        </span>
                      </label>
                    </div>
                  </Col>
                ))}
            </Row>
            {(newConnectionProduct?.value || 0) > 0 && (
              <div className="bold mb-3 text-center">
                {`${newConnectionProduct?.name || ""} - Once off ($${newConnectionProduct?.value})`}
              </div>
            )}
            <div className="bold text-center">
              {"Initial Payment (Including First Month)"}
            </div>
            <div
              className="bold font-orange text-center"
              style={{fontSize: 35}}>
              {`$${initialPayment}`}
            </div>
            <div className="bold text-center">{"Monthly"}</div>
            <div
              className="bold font-orange text-center"
              style={{ fontSize: 35 }}>
              {`$${monthly + (internetPlan?.value || 0)}`}
            </div>
            <Row>
              <Col>
                <Button
                  variant="darkblue vw-button mx-auto my-3 d-block"
                  onClick={async () => {
                    const productAddons = selectedProducts.filter((sp) => sp.type !== "internet");

                    let newProducts = productAddons.concat([internetPlan]);
                    newProducts = newProducts.filter((np, i) => newProducts.findIndex((p) => p.id === np.id) === i);
                    setFormData({
                      selectedProducts: newProducts,
                    });
                    await onSelectProduct(internetPlan);

                    return window.location.href = "/register";
                  }}>
                  {"Confirm"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="minimum text-center">
                {`The plan is month to month and automatically renewed. Total minimum cost is $${
                  initialPayment
                } inc. GST.`}
              </Col>
            </Row>
            {cisLink && (
              <Row>
                <Col>
                  <div className="text-center">
                    <a
                      href={cisLink || "javascript:void(0);"}
                      target={cisLink ? "_blank" : ""}
                      className="font-black underline">
                      {"Click here to view the critical information summary."}
                    </a>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProductSelector;
