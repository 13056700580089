import React from "react";
import {Row, Col} from "react-bootstrap";
import {Link} from "gatsby";

import Banner from "../components/section/banner";
import AboutSection from "../components/section/about";
import PlanSection from "../components/section/plans";
import WhySection from "../components/section/why";
import IPSection from "../components/section/ip-section";
import Peer from "../components/section/provider";
import SEO from "../components/section/seo";
import Main from "../components/main";
import Header from "../components/section/header";
import Footer from "../components/section/footer";

export default function IndexPage() {
  return (
    <>
      <Main>
        <SEO title="GETGUD Internet">
          <meta name="description" content="An ISP built for High Performance" />
        </SEO>
        <Header />
        <Banner>
          <Row>
            <Col xs={12} lg={6}>
              <div className="main-text font-white">
                {"An Internet Service Provider built for"}
              </div>
              <div className="main-text font-cyan mb-4">{"high-performance gaming"}</div>
              <div className="sub-text font-white font-secondary">
                {"Low latency and high speeds."}
              </div>
              <div className="font-cyan font-primary-bold sub-text mb-4">
                {"It's time to Getgud."}
              </div>

              <Link to="/?section=plans" className="btn btn-white-orange font-regular">
                {"Sign up"}
              </Link>
            </Col>
          </Row>
        </Banner>
        <AboutSection />
        <PlanSection />
        <WhySection />
        <IPSection />
        <Peer />
        <Footer />
      </Main>
    </>
  );
}
