import React, {useRef, useEffect, Fragment} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import {useQueryParam, StringParam} from "use-query-params";
import {useQuery} from "@apollo/client";

import {executeScroll} from "./utils";
import ProductSelector from "../bootstrap/product-selector";
import AddressLocator from "../address-selector";
import {getFormData, setFormData} from "../manager/form";
import {getBuildingCIS} from "../logic/address";
import {CIS_LINK} from "../../utils/constants";

const PlanSection = () => {
  const myRef = useRef(null);
  const purchaseRef = useRef(null);
  const [defaultTab] = useQueryParam("section", StringParam);
  const [buildingCode] = useQueryParam("building", StringParam);
  // const [unitno] = useQueryParam("unitno", StringParam);
  const [selectedProduct, setProduct] = useAsyncSetState(null);
  const [selectedBuilding, setBuilding] = useAsyncSetState(null);
  const [step, setStep] = useAsyncSetState({
    addressSelector: true,
    productSelector: false,
    purchase: false,
  });
  const {selectedProducts: formProducts} = getFormData();

  useEffect(() => {
    if (defaultTab === "plans") {
      executeScroll(myRef);
    }
  }, [defaultTab, myRef, executeScroll]);

  useEffect(() => {
    if (formProducts?.length > 0 && step.addressSelector) { // Clears selectedProducts in store if existing on first mount
      setFormData({selectedProducts: []})
    }
  }, [step]);

  const scrollTo = (ref) => {
    executeScroll(ref);
  };

  return (
    <Fragment>
      <div className="plan-section" ref={myRef}>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="main-text font-white mb-1 text-center">
                Get connected to one of
              </div>
              <div className="main-text font-orange mb-4 text-center">
                Australia's fastest networks
              </div>
              <div className="sub-text font-secondary font-white mb-5 text-center">
                Our plans are designed for high speeds, enchancing your ability to share content, stream 4k <br/>
                entertainment and have a seamless gaming experience. Choose one of our no-lock-in plans today.
              </div>
            </Col>
          </Row>
          {step.addressSelector && (
            <Row className="mb-5">
              <Col className="vw-sup-locator">
                <AddressLocator
                  // unitParams={unitno}
                  buildingCodeParams={buildingCode}
                  setBuilding={async(data) => {
                    await setStep({
                      productSelector: true,
                      addressSelector: false,
                      purchase: false,
                    });
                    return setBuilding(data);
                  }}
                  inline
                  isWhite
                  highlight
                  showLocator
                />
              </Col>
            </Row>
          )}
          {step.productSelector && (
            <Row>
              <Col xs={12}>
                <ProductSelector
                  cisLink={selectedBuilding?.cisLink}
                  buildingCode={selectedBuilding?.code}
                  onSelectProduct={async(product) => {
                    await setStep({
                      productSelector: true,
                      addressSelector: false,
                      purchase: true,
                    });
                    await setProduct(product);
                    return window.location.href = "/register";
                  }}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

export default PlanSection;
