import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CarImage from "../../images/bg/getgudcar.svg"
import { useQueryParam, StringParam } from "use-query-params";
import {executeScroll} from "./utils";

export default function AboutSection(props) {
  const myRef = useRef(null);
  const [defaultTab] = useQueryParam("section", StringParam);

  useEffect(() => {
    if (defaultTab === "about") {
      executeScroll(myRef);
    }
  }, [defaultTab, myRef]);

  return (
    <div className="about-section" ref={myRef}>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div className="main-text font-white">
              <div className="d-none d-lg-block">
                <div>{"Connect to a"}</div>
                <div className="font-orange">{"purpose-built"}</div>
                <div>
                  <span className="font-orange">
                    {"gaming"}
                  </span>
                  {" network"}
                </div>
              </div>
              <div className="d-block d-lg-none">
                {"connect to the "}
                <span className="font-orange">cutting-edge</span>
                {" of broadband"}
              </div>
            </div>
            <div className="sub-text font-secondary font-white mb-5" >
              {"Our service is designed to provide a high-speed, low latency experience with maximum uptime. We directly peer with major gaming and content providers to maximise speeds and operate"}<br/>
              {" round-the-clock network support to keep you online. External IPv4 address included."}
            </div>
          </Col>
          <Col>
            <img
              src={CarImage}
              alt="car"
              className="car-home"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
